import { createSlice } from '@reduxjs/toolkit';
// utils
import ArcadaApiService from 'helpers/service/arcadaApiService';
import { getFormData } from 'utils/formData';
import uuidv4, { checkValidUUID } from 'utils/uuidv4';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { TABLE_ROWS_COUNT_OPTIONS_MAX } from 'utils/table';
//
import { dispatch } from '../store';
import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  // LOADING
  isLoading: false,
  isLoadingVoteQuestionUserAnswers: false,
  isLoadingVoteParticipatedUsers: false,

  error: null,
  voteSuccess: null,
  votesList: null,
  voteResults: null,
  voteQuestionUserAnswers: null,
  voteParticipatedUsers: null,
  vote: {
    questions: [],
  },
  attachments: [],
};

const slice = createSlice({
  name: 'vote',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingVoteQuestionUserAnswers(state) {
      state.isLoadingVoteQuestionUserAnswers = true;
    },

    startLoadingVoteParticipatedUsers(state) {
      state.isLoadingVoteParticipatedUsers = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },

    hasVoteSuccess(state) {
      state.error = null;
      state.voteSuccess = true;
    },

    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.votesList = action.payload;
    },

    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.vote = action.payload;
    },

    getVoteQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.vote = action.payload;
      state.attachments = action.payload?.questions?.map((question) => question.attachment_files);
    },

    getVoteResultsSuccess(state, action) {
      state.isLoading = false;
      state.voteResults = action.payload;
    },

    getVoteQuestionUserAnswersSuccess(state, action) {
      state.isLoadingVoteQuestionUserAnswers = false;
      state.voteQuestionUserAnswers = action.payload;
    },

    getVoteParticipatedUsersSuccess(state, action) {
      state.isLoadingVoteParticipatedUsers = false;
      state.voteParticipatedUsers = action.payload;
    },

    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.vote.questions.push({
        ...newColumn,
        answers: [
          { id: uuidv4(), title: 'Կողմ', title_ru: 'За', title_en: 'For' },
          { id: uuidv4(), title: 'Դեմ', title_ru: 'Против', title_en: 'Against' },
          { id: uuidv4(), title: 'Ձեռնպահ', title_ru: 'Воздержался', title_en: 'Abstain' },
        ],
      });
      state.attachments.push([]);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.vote.questions = columns;
    },

    persistColumn(state, action) {
      state.vote.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { task, columnId } = action.payload;
      if (state.vote.questions.length) {
        state.vote.questions.every((question) => {
          if (question?.id === columnId || !question.id) {
            question?.answers.push(task);
            return false;
          }
          return true;
        });
      }
    },

    addAttachment(state, action) {
      const { file, index, isUpdate } = action.payload;

      if (!isUpdate) {
        state.attachments[index] = [...(state.attachments[index] || []), ...file];
        return;
      }
      state.attachments.splice(index, 1, file);
    },

    deleteAttachments(state, action) {
      const { columnIndex, isCompletely } = action.payload;

      if (isCompletely) {
        state.attachments.splice(columnIndex, 1);
        return;
      }
      state.attachments = state.attachments.map((attachment, index) => {
        if (index === columnIndex) return null;
        return attachment;
      });
    },

    deleteTask(state, action) {
      const { answerId, questionId } = action.payload;

      state.vote.questions = state.vote.questions.map((question) => {
        if (question.id === questionId) {
          question.answers = question.answers.filter((answer) => answer.id !== answerId);
        }
        return question;
      });
    },

    updateColumnSuccess(state, action) {
      const { id, updateColumn } = action.payload;

      state.isLoading = false;
      state.vote.questions = state.vote.questions.map((question) => {
        if (question.id === id) return updateColumn;
        return question;
      });
    },

    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;

      state.isLoading = false;
      state.vote.questions = state.vote.questions.filter((question) => question.id !== columnId);
    },

    resetHasError(state) {
      state.error = null;
    },

    resetVote(state) {
      state.vote = {
        questions: [],
      };
      state.attachments = [];
      state.error = null;
      state.voteSuccess = null;
    },

    resetVoteResults(state) {
      state.voteResults = null;
      state.error = null;
    },

    resetVoteQuestionUserAnswers(state) {
      state.voteQuestionUserAnswers = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export const { addAttachment, deleteAttachments } = slice.actions;

export function getVotes(skip = 0, take = TABLE_ROWS_COUNT_OPTIONS_MAX[0], ordering = '-created_at', search, filters) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await ArcadaApiService.vote.getVotes({
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getEntitiesSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getVoteQuestions(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await ArcadaApiService.vote.getVoteQuestions(id);
      dispatch(slice.actions.getVoteQuestionsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVoteResults(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const { data } = await ArcadaApiService.vote.getVoteResults(id);
      dispatch(slice.actions.getVoteResultsSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVoteParticipatedUsers(
  id,
  skip = 0,
  take = TABLE_ROWS_COUNT_OPTIONS_MAX[0],
  ordering = '-created_at',
  search,
  filters
) {
  return async () => {
    dispatch(slice.actions.startLoadingVoteParticipatedUsers());
    try {
      const { data } = await ArcadaApiService.vote.getVoteParticipatedUsers(id, {
        answered: 1,
        skip,
        take,
        ordering,
        search,
        ...filters,
      });
      dispatch(slice.actions.getVoteParticipatedUsersSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVoteQuestionUserAnswers(id) {
  return async () => {
    dispatch(slice.actions.startLoadingVoteQuestionUserAnswers());
    try {
      const { data } = await ArcadaApiService.vote.getVoteQuestionUserAnswers(id);
      dispatch(slice.actions.getVoteQuestionUserAnswersSuccess(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addVote({ vote, data: payload, attachments }) {
  dispatch(slice.actions.startLoading());
  // eslint-disable-next-line no-unused-vars
  const { files, fileDeleteIds, ...other } = payload || {};
  const formData = getFormData(other);

  return async () => {
    try {
      const { data } = await ArcadaApiService.vote.addVote(formData);

      const questions = vote.questions.map((question) => {
        const { id, ...other } = question;
        let newQuestion = { id, ...other };

        if (checkValidUUID(question?.id)) newQuestion = other;

        newQuestion.answers = other.answers.map((answer) => {
          if (checkValidUUID(answer?.id)) {
            // eslint-disable-next-line no-unused-vars
            const { id, ...other } = answer;
            return other;
          }
          return answer;
        });

        return newQuestion;
      });

      if (questions?.length) {
        const { data: newData } = await ArcadaApiService.vote.addVoteQuestions(data?.id, { ...data, questions });

        if (attachments?.length) {
          await Promise.all(
            // eslint-disable-next-line consistent-return
            attachments.map(async (file, index) => {
              if (file?.length) {
                // eslint-disable-next-line no-return-await
                return await Promise.all(
                  file.map(async (item) => {
                    if (item?.id) return;
                    const fileFormData = getFormData();
                    fileFormData.append('file', item);
                    fileFormData.set('question', newData?.questions[index]?.id);
                    // eslint-disable-next-line no-return-await, consistent-return
                    return await ArcadaApiService.vote.addVoteQuestionAttachments(fileFormData);
                  })
                );
              }
            })
          );
        }
      }
      dispatch(slice.actions.getVoteQuestionsSuccess(data));
      dispatch(slice.actions.hasVoteSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVote({ vote, data: payload, attachments }) {
  dispatch(slice.actions.startLoading());
  // eslint-disable-next-line no-unused-vars
  const { files, fileDeleteIds, ...other } = payload || {};
  const formData = getFormData(other);

  return async () => {
    try {
      const deleteFileIds = fileDeleteIds?.filter((id) => id);
      if (deleteFileIds?.length) await ArcadaApiService.vote.deleteBulkVoteQuestionAttachments({ ids: deleteFileIds });

      const { data } = await ArcadaApiService.vote.editVote(vote?.id, formData);

      const questions = vote.questions.map((question) => {
        const { id, ...other } = question;
        let newQuestion = { id, ...other };

        if (checkValidUUID(question?.id)) newQuestion = other;

        newQuestion.answers = other.answers.map((answer) => {
          if (checkValidUUID(answer?.id)) {
            // eslint-disable-next-line no-unused-vars
            const { id, ...other } = answer;
            return other;
          }
          return answer;
        });

        return newQuestion;
      });

      const { data: newData } = await ArcadaApiService.vote.addVoteQuestions(vote?.id, { ...data, questions });

      if (attachments?.length) {
        await Promise.all(
          // eslint-disable-next-line consistent-return
          attachments.map(async (file, index) => {
            if (file?.length) {
              // eslint-disable-next-line no-return-await
              return await Promise.all(
                file.map(async (item) => {
                  if (item?.id) return;
                  const fileFormData = getFormData();
                  fileFormData.append('file', item);
                  fileFormData.set('question', newData?.questions[index]?.id);
                  // eslint-disable-next-line no-return-await, consistent-return
                  return await ArcadaApiService.vote.addVoteQuestionAttachments(fileFormData);
                })
              );
            }
          })
        );
      }
      dispatch(slice.actions.getVoteQuestionsSuccess(newData));
      dispatch(slice.actions.hasVoteSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVote(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.vote.deleteVote(id);
      dispatch(slice.actions.hasSuccess());
      dispatch(getVotes());
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function sendVote({ voteId, residents, ...rest }) {
  const formData = getFormData(rest);
  (residents || []).map((id) => formData.append('residents', id));

  return async () => {
    try {
      await ArcadaApiService.vote.sendVote(voteId, formData);
      dispatch(
        globalNotificationSlice.actions.hasNotification(
          FormatSuccessResponse(['Քվեարկությունը հաջողությամբ ուղարկված է'])
        )
      );
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function createColumn(newColumn) {
  return () => {
    try {
      dispatch(slice.actions.createColumnSuccess(newColumn));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumn(questionId, updateColumn) {
  return async () => {
    try {
      dispatch(slice.actions.updateColumnSuccess({ id: questionId, updateColumn }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteColumn(columnId, columnIndex) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
      dispatch(slice.actions.deleteAttachments({ columnIndex, isCompletely: true }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function persistColumn(newColumnOrder) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

export function persistCard(columns) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

export function addTask({ task, columnId }) {
  return () => {
    dispatch(slice.actions.addTask({ task, columnId }));
  };
}

export function deleteTask({ answerId, questionId }) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ answerId, questionId }));
  };
}

export function resetHasError() {
  return () => {
    dispatch(slice.actions.resetHasError());
  };
}

export function resetVote() {
  return () => {
    dispatch(slice.actions.resetVote());
  };
}

export function resetVoteResults() {
  return () => {
    dispatch(slice.actions.resetVoteResults());
  };
}

export function resetVoteQuestionUserAnswers() {
  return () => {
    dispatch(slice.actions.resetVoteQuestionUserAnswers());
  };
}
