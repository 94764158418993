import { createSlice } from '@reduxjs/toolkit';
import ArcadaApiService from 'helpers/service/arcadaApiService';
import { FormatErrorResponse, FormatSuccessResponse } from 'utils/formatErrorResponse';
import { dispatch } from '../store';

import { slice as globalNotificationSlice } from './globalNotifications';

const initialState = {
  isLoading: false,
  issueTypes: null,
  issueType: null,
  error: null,
};

const slice = createSlice({
  name: 'issueType',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.issueTypes = [...action.payload];
    },
    getEntitySuccess(state, action) {
      state.isLoading = false;
      state.issueType = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasSuccess(state) {
      state.isLoading = false;
      state.error = null;
    },
    resetEntity(state) {
      state.issueType = null;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getIssueTypes() {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await ArcadaApiService.issueType.getIssueTypes();
      dispatch(slice.actions.getEntitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function getIssueType(id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await ArcadaApiService.issueType.getIssueType(id);
      dispatch(slice.actions.getEntitySuccess(response.data));
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addIssueType(data) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      await ArcadaApiService.issueType.addIssueType(data);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Դիմումի տեսակն ավելացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function editIssueType(data, id) {
  return async () => {
    dispatch(slice.actions.startLoading());

    try {
      await ArcadaApiService.issueType.editIssueType(data, id);
      dispatch(slice.actions.hasSuccess());
      dispatch(globalNotificationSlice.actions.hasNotification(FormatSuccessResponse(['Դիմումի տեսակը թարմացված է'])));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function deleteIssueType(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ArcadaApiService.issueType.deleteIssueType(id);
      dispatch(slice.actions.hasSuccess());
      dispatch(getIssueTypes());
    } catch (error) {
      dispatch(globalNotificationSlice.actions.hasNotification(FormatErrorResponse(error)));
    }
  };
}

export function resetIssueType() {
  return () => {
    dispatch(slice.actions.resetEntity());
  };
}
